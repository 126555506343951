import {
  userAnalyticsGateway,
  EventMetadata,
  CioTrackRequest,
  CioIdentifyRequest,
} from '@msl/user-analytics-gateway-sdk';
import { reportMonitoringException } from '@mfl/framework';

export type { CioTrackRequest, CioIdentifyRequest };

export type AnalyticEvent = {
  eventName: string;
  eventMetadata: EventMetadata;
};

// Generate a random ID to tie all events sent during a single "page view" - reallocated on page load
const pageviewId = Math.floor(Math.random() * 1e13);

/**
 * Sent a user analytic event - the basic details are filled-in
 *
 * The method returns immediately without waiting for a response
 */
export function trackEvent(event: AnalyticEvent) {
  async function track() {
    try {
      await userAnalyticsGateway.userDataTrack({
        ...event,
        sessionId: getFromCookies('ws_session_id'),
        userHash: getFromCookies('ws_user_hash'),
        pageviewId,
        screenWidth: screen.width,
        screenHeight: screen.height,
        pageTitle: document.title,
        distinctId: getDistinctId(),
        clientTimestamp: Math.floor(Date.now() / 1000).toString(),
      });
    } catch (e) {
      console.warn('Error tracking user event', e);
      reportMonitoringException(e);
    }
  }

  // No awaiting, no need to block the flow
  track();
}

/**
 * Report a Customer IO event
 *
 * Does not wait for a response
 */
export function cioTrack(event: CioTrackRequest) {
  async function track() {
    try {
      await userAnalyticsGateway.cioTrack(event);
    } catch (e) {
      console.warn('Error tracking cio user event', e);
      reportMonitoringException(e);
    }
  }

  // No awaiting, no need to block the flow
  track();
}

/**
 * Report a Customer IO identify event
 *
 * Does not wait for a response
 */
export function cioIdentify(event: CioIdentifyRequest) {
  async function identify() {
    try {
      await userAnalyticsGateway.cioIdentify(event);
    } catch (e) {
      console.warn('Error tracking cio user identify event', e);
      reportMonitoringException(e);
    }
  }

  // No awaiting, no need to block the flow
  identify();
}

/**
 * The cookieStore api is still not widly supported
 * so it is not included in the types :(
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/cookieStore
 *
 * This function is ugly, but better than using any bloatware
 */
function getFromCookies(name: string): string | undefined {
  try {
    const startMarker = name + '=';
    const cookies = document.cookie;
    const markerStartIndex = cookies.indexOf(startMarker);

    if (markerStartIndex === -1) return undefined;

    const startIndex = markerStartIndex + name.length;
    const endMarker = cookies.indexOf(';', startIndex);

    /* if the cookie value is placed in the end */
    if (endMarker === -1) return cookies.substring(startIndex + 1);
    console.log(cookies.substring(startIndex + 1, endMarker));

    return cookies.substring(startIndex + 1, endMarker);
  } catch (err) {
    console.warn(`Error extracting cookie "${name}"`, err);
    reportMonitoringException(err);
  }
}

/**
 * The "distinct ID" is generated by a package named "Alooma" (it is very very obsolete) used by webapp frontend
 * Alooma generates the ID and places it inside a cookie's value (mp_ws_alooma_alooma)
 *
 * This is temporary
 */
function getDistinctId() {
  try {
    const cookie = document.cookie;
    const startMarker = 'distinct_id%22%3A%20%';
    const start = cookie.indexOf(startMarker);

    if (start === -1) return undefined;
    const end = cookie.indexOf('%22%2C%22%24', start + startMarker.length);

    return cookie.substring(start + startMarker.length, end);
  } catch (err) {
    console.warn(`Error extracting the distinct_id cookie`, err);
    reportMonitoringException(err);
  }
}
