export type BasicUserDetails = {
  accountId: string;
  domainId: string;
  userId: string;
};

export type AuthorizationDetails = {
  permissions: string[];
  entitlements: string[];
};

class CurrentUser {
  private basicInfo: BasicUserDetails | undefined;
  private authInfo: AuthorizationDetails | undefined;

  private authSet: Set<string> | undefined;

  public init(info: BasicUserDetails, auth: AuthorizationDetails): void {
    this.basicInfo = info;
    this.authInfo = auth;

    this.authSet = new Set<string>([
      ...this.authInfo.permissions,
      ...this.authInfo.entitlements,
    ]);

    console.info('CURRENT USER', { info, auth });
  }

  /**
   * return the user details, use this for destructuring\
   * Use the named ones for single field access (`currentUser.accountId()`)
   */
  public getInfo(): BasicUserDetails {
    // Asking for a user too early, throw
    if (!this.basicInfo) throw new Error('User info still not available');

    return this.basicInfo;
  }

  public get info(): BasicUserDetails {
    return this.getInfo();
  }

  /** Get the account ID */
  public get accountId(): string {
    return this.getInfo().accountId;
  }

  /**
   * Get the current domain ID ("context" domain)\
   * The domain ID is kept per browser tab - kept in sessionStorage
   */
  public get domainId(): string {
    return this.getInfo().domainId;
  }

  /** Get the user ID */
  public get userId(): string {
    return this.getInfo().userId;
  }

  /**
   * Authorization checkes
   */
  public isAllowedTo(permission: string): boolean {
    // Asking for authorization checkes too early
    if (!this.authSet) throw new Error('User auth info still not available');

    return this.authSet.has(permission);
  }

  /**
   * Authorization checkes
   */
  public isEntitledTo(entitlement: string): boolean {
    // Asking for authorization checkes too early
    if (!this.authSet) throw new Error('User auth info still not available');

    return this.authSet.has(entitlement);
  }
}

export const currentUser = new CurrentUser();
