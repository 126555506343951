<script setup lang="ts">
import { WsButton } from '@mfl/common-components';
import { AccountBasicInfoResponse, AccountStages } from '@msl/user-gateway-sdk';

import {
  EntitelmentTypes,
  PermissionTypes,
  WEBAPP_BASE_URL,
  webAppPopupFlags,
} from '../consts';
import { currentUser } from '../session';
import translations from './header.strings';

export type UserActionBtn = {
  aid: string;
  label: string;
  color?: 'primary' | 'promotion' | 'white';
  action?: string;
  available?: boolean;
  link?: string;
  variant?: 'contained' | 'outlined' | 'text';
};

const { stage, hasPlansToUpgrade, scheduleDemoAvailable } =
  defineProps<AccountBasicInfoResponse>();

const userActions: UserActionBtn[] = [
  {
    aid: 'user-hear-action-upgrade',
    label: translations.upgradeNowBtn,
    color: 'promotion',
    available:
      currentUser.isAllowedTo(PermissionTypes.accountBilling) &&
      stage !== AccountStages.soloPro &&
      hasPlansToUpgrade &&
      stage !== AccountStages.grace,
    link: `${WEBAPP_BASE_URL}/?${webAppPopupFlags.upgradeNow}`,
  },
  {
    aid: 'user-hear-action-schedule-demo',
    label: translations.scheduleDemoBtn,
    color: 'primary',
    available: scheduleDemoAvailable,
    link: `${WEBAPP_BASE_URL}/scheduler?ref=solo_to_teams&o=ws1_nav`,
  },
  {
    aid: 'user-hear-action-try-for-teams',
    label: translations.tryForTeamsBtn,
    color: 'primary',
    available:
      currentUser.isEntitledTo(EntitelmentTypes.commonSoloFeatures) &&
      !currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures),
    link: `${WEBAPP_BASE_URL}/?${webAppPopupFlags.tryForTeams}`,
  },
];

const finalActions = userActions.filter((el) => el.available);
</script>
<template>
  <WsButton
    v-for="btn in finalActions"
    :key="btn.aid"
    :href="btn.link"
    target="_blank"
    :color="btn.color"
    :label="btn.label"
    size="sm"
    :aid="btn.aid"
  />
</template>
<style scoped>
.q-btn {
  text-transform: none;
}
</style>
