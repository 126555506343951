<script setup lang="ts">
import { AccountBasicInfoResponse, AccountStages } from '@msl/user-gateway-sdk';

import { PermissionTypes, WEBAPP_BASE_URL, webAppPopupFlags } from '../consts';
import { currentUser } from '../session';

import translations from './header.strings';
import { UserActionBtn } from './header-action-buttons.vue';

const { stage, upgradeDiscountPercent, remainDays, isSubscriptionActive } =
  defineProps<AccountBasicInfoResponse>();

const announcementText = getAnnouncementText();
function getAnnouncementText() {
  switch (stage) {
    case undefined:
      return '';
    case AccountStages.grace:
      return translations.subscriptionGracePeriodText;
    case AccountStages.trialExpired:
      return translations.subscriptionTrialEndedText;
    case AccountStages.trialActive:
      return (
        upgradeDiscountPercent
          ? translations.headerTrialActiveText
          : translations.headerTrialActiveTextNoDiscount
      )
        .replace('{remainDays}', remainDays?.toString() || '0')
        .replace('{discount}', upgradeDiscountPercent?.toString() || '0');
    default:
      return !isSubscriptionActive ? translations.subscriptionExpiredText : '';
  }
}

const announcementBtns: UserActionBtn[] = [
  {
    aid: 'announcment-bar-link-upgrade',
    label: translations.upgradeNowBtn,
    link: `${WEBAPP_BASE_URL}/?${webAppPopupFlags.upgradeNow}`,
    color: 'white',
    variant: 'text',
    available:
      currentUser.isAllowedTo(PermissionTypes.accountBilling) &&
      stage == AccountStages.trialExpired,
  },
  {
    aid: 'announcment-bar-link-cancel-team',
    label: translations.cancelTeamAccountBtn,
    link: `${WEBAPP_BASE_URL}/?${webAppPopupFlags.cancelTeamAccount}`,
    color: 'white',
    variant: 'text',
    available:
      currentUser.isAllowedTo(PermissionTypes.accountBilling) &&
      stage == AccountStages.trialExpired,
  },
  {
    aid: 'announcment-bar-link-renew',
    label: translations.subscriptionRenewBtn,
    link: `${WEBAPP_BASE_URL}/?${webAppPopupFlags.upgradeNow}`,
    color: 'white',
    variant: 'text',
    available:
      currentUser.isAllowedTo(PermissionTypes.accountBilling) &&
      (stage == AccountStages.grace || stage == AccountStages.expired),
  },
];

const finalAnnouncementBtns = announcementBtns.filter((el) => el.available);
</script>
<template>
  <div v-if="announcementText" class="announcment-bar">
    <div class="text-md">
      {{ announcementText }}
      <a
        v-for="link in finalAnnouncementBtns"
        :key="link.aid"
        :href="link.link"
        target="_blank"
        class="announcment-bar-link text-underline"
      >
        {{ link.label }}
      </a>
    </div>
  </div>
</template>
<style scoped>
.announcment-bar {
  padding: 10px;
  height: 40px;
  text-align: center;
  gap: 10px;
  flex-wrap: nowrap;
  display: flex;
  min-height: 40px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  background-color: rgb(var(--color-promotion));
}

.announcment-bar-link {
  color: white;
  padding-left: 10px;
}
</style>
