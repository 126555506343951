<script setup lang="ts">
import { AccountBasicInfoResponse } from '@msl/user-gateway-sdk';

import translations from './header.strings';

const { userEmail } = defineProps<AccountBasicInfoResponse>();

const alert = translations.headerWarningSignEmail.replace(
  '{accountOwnerEmail}',
  userEmail || ''
);
</script>
<template>
  <div class="impersonation-alert">{{ alert }}</div>
</template>
<style scoped>
.impersonation-alert {
  display: flex;
  width: 100%;
  height: 30px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  background: #ea4335;
  color: #ffffff;
}
</style>
